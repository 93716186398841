import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject } from '@angular/core';
import { Injectable, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { environment } from 'environments/environment';

import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Go } from '../store/actions/router.action';

@Injectable()
export class AppErrorInterceptor implements HttpInterceptor {
  LOGOUT_REDIRECT_URI = environment.keys.oktaLogoutConfig.redirectUri;

  constructor(private injector: Injector,
              private snackBar: MatSnackBar,
              @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) {
  }

  store = this.injector.get(Store);


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          switch (error.status) {
            case 400:{
              if(error.error?.errors?.deployOnly){
                this.snackBar.open(error.error.errors.deployOnly[0], 'X', {
                  duration: 3000,
                  horizontalPosition: 'end',
                  verticalPosition: 'top'
                });
              }
              break;
            }
            case 401:{
              this._oktaAuth.signOut({
                postLogoutRedirectUri: this.LOGOUT_REDIRECT_URI
              });
              this.store.dispatch(Go(['/']));
              break;
            }
            case 403: {
              this.snackBar.open(error.error.message, 'X', {
                duration: 3000,
                horizontalPosition: 'end',
                verticalPosition: 'top'
              });
              break;
            }
            case 404:
              this.store.dispatch(Go(['/404']));
              break;
          }

          return throwError(error);
        })
      );
  }
}
